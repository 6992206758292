
import { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonText, 
    IonButton, 
    IonInput,

    onIonViewDidEnter,
  
  } from '@ionic/vue';
import { defineComponent, ref } from '@vue/runtime-core';
import * as yup from 'yup';
import { useForm, useField } from 'vee-validate';
import { ApiError, isApiError } from "@/lib/api-endpoints";
import { useStore } from '@/store';
import t from '@/translation';

export default  defineComponent({
  name: 'Login',
  components: { 
    IonContent, 
    IonPage, 
    IonGrid, 
    IonRow, 
    IonCol, 
    IonText, 
    IonButton, 
    IonInput
    
  },
  setup(){
  
    const forgotSchema = yup.object({
      email: yup.string().email().max(50).required().label(t("Email")),
    });

    // Create a form context with the validation schema
    const { values, validate, errors: formErrors, resetForm } = useForm({
      validationSchema: forgotSchema,
    });

    const { errorMessage: emailError, value: emailValue} = useField ('email');

    const showErrors = ref(false);

    const store = useStore();

    const resetDone = ref(false);

    onIonViewDidEnter(()=>{
      resetDone.value = false;
      resetForm();
    })

    async function onSubmit(){
      const result = await validate();
      showErrors.value = true;
      if (result.valid){

        const userData: ApiError | {} = await store.dispatch("resetPassword", values);
        if (! isApiError(userData)){
          resetDone.value = true;
        }
      }
    }

    return {
      values,
      validate,
      formErrors,
      onSubmit,
      emailError,
      emailValue,
      showErrors,
      resetDone,
      store,
    };
  },
});
